<template>
  <b-container
    fluid
    class="main-container p-4"
  >
    <b-container class="sub-container mt-6 mb-6 pt-5">
      <b-col
        cols="6"
        class="pr-0 ml-5"
      >
        <img
          src="/images/published-view-img.svg"
          alt=""
          width="355"
          height="320"
        >
      </b-col>
      <b-col
        cols="5"
        class="mt-5 pl-0 text-left"
      >
        <h6 class="title-txt">
          Published! Await for matches...
        </h6>
        <p class="text-div">
          Our Customer Success Manager will hand-pick recruiters who fit you best. Check your email for updates within 1-3 work days.
          <br>
          <br>
          <strong><u>Questions?</u></strong>
          <br>
          Write to success@relancer.com We're happy to assist!
        </p>
        <b-btn
          variant="primary"
          class="btn"
          :to="`/business/${$store.state.auth.selectedBusiness.id}/projects`"
        >
          Back to projects
        </b-btn>
      </b-col>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: 'PublishedView',
}
</script>

<style lang="scss" scoped>
  .main-container {
    background: #f8eeec;
    text-align: center;
    height: 100vh;
  }
  .sub-container {
    display: flex;
    margin:auto;
  }
  .title-txt {
  font-style: italic;
  font-weight: 800;
  font-size: 26px;
  line-height: 39px;
  color: #EE8B7B;
  }
  .text-div {
    font-size: 16px;
  }

</style>
